body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#wrapper{
  width: 800px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  float: right;
  padding-top: 15px;
}

.navButton{
  text-align: center;
}

header{
  height: 100px;
  margin-top: 20px;
}
button {
  background: white;
  border-color: white;
  outline: 0;
  border-image: none;
  border-style: solid;
  text-align: left;
}

button:hover{
  cursor: pointer;
  opacity: .5;
}

ul{
  list-style-type: circle;
}

table{
  margin-left: auto;
  margin-right: auto;
}

header> h1 {
  float: left;
  color: #062F4F
}

p{
  color: #062F4F
}

th{
  height: 50px;
}

td{
  color: #062F4F;
  vertical-align: top;
  width: 260px;
  height: 50px;
}

td > button {
  text-align: left;
}

nav > ul{
  display: inline-flex;
  list-style-type: none;
}

.subHeader{
  text-align: center;
}

.subContent{
  margin-bottom: 70px;
}

#csumbLogo{
  width: 400px;
}